import React, { useEffect, useState } from "react";
import {
  Container,
  CssBaseline,
  Typography,
  Paper,
  Grid,
  ThemeProvider,
  createTheme,
  Divider,
  AppBar,
  Toolbar,
  InputBase,
  MenuIcon,
  Card,
  Box,
  CardContent,
  CardMedia,
  Avatar,
  Link,
  IconButton,
  Collapse,
  ListItemText,
  List,
  ListItem,
} from "@mui/material";

import NewsStory from "./components/NewsStory";
import sendRequest from "./utils/sendRequest";
const App = () => {
  const [darkMode, setDarkMode] = useState(getInitialDarkModeState);
  const [loading, setLoading] = useState(false);
  const [newsStories, setNewsStories] = useState([]);
  const [metaData, setMetaData] = useState({});
  const endpointMap = {
    "/": "/news",
    "/whyral": "/news_whyral",
  };

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const data = await sendRequest(
          "/meta_data",
          null,
          null,
          "GET",
          setLoading
        );
        setMetaData(data);
        setLoading(false); // Set loading to false after metadata is fetched
      } catch (e) {
        console.error(e);
      }
    };

    fetchMetaData();
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (event) => {
      setDarkMode(event.matches);
    };

    mediaQuery.addEventListener("change", handleChange);

    const fetchNews = async () => {
      var category = window.location.pathname.slice(1);
      if (category === "whyral") {
        category = "viral";
      }
      try {
        const data = await sendRequest(
          "/news",
          null,
          category ? { category: category } : null,
          "GET",
          setLoading
        );
        setNewsStories(data);
      } catch (e) {
        console.error(e);
      }
    };

    fetchNews();
    const intervalId = setInterval(fetchNews, 60000);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
      clearInterval(intervalId);
    };
  }, []);

  function getInitialDarkModeState() {
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  }

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  const [open, setOpen] = React.useState(true);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Container component="main" maxWidth="lg" element={Paper}>
        <CssBaseline />
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            alignItems={"center"}
            direction={"column"}
          >
            <Paper
            <Typography variant="h2" component="h2">
              News
            </Typography>
            <Divider />
          </Grid>
        </Grid>
      </Container> */}
      {Object.keys(metaData).length > 0 && (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" variant="outlined">
              <Toolbar>
                <Typography
                  variant="h4"
                  noWrap
                  //align="center"
                  //align="center"
                  component="h4"
                  sx={{
                    flexGrow: 1,
                    //display: { xs: "none", sm: "block" },
                    //alignItems: { xs: "left", sm: "center" },
                  }}
                >
                  News
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <Container maxWidth="lg" sx={{ paddingY: 2 }}>
            <Grid container spacing={2} direction={"column"}>
              {Object.entries(newsStories).map((newsStory) => (
                <NewsStory
                  newsStory={newsStory[1]}
                  theme={theme}
                  metaData={metaData}
                  key={newsStory[0]}
                />
              ))}
            </Grid>
          </Container>
        </>
      )}
    </ThemeProvider>
  );
};

export default App;
