import React from "react";
import moment from "moment";
import {
  Container,
  CssBaseline,
  Typography,
  Paper,
  Grid,
  ThemeProvider,
  createTheme,
  Divider,
  AppBar,
  Toolbar,
  InputBase,
  MenuIcon,
  Card,
  Box,
  CardContent,
  CardMedia,
  Avatar,
  Link,
  IconButton,
  Collapse,
  ListItemText,
  List,
  ListItem,
  Button,
  ListItemButton,
} from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineDot from "@mui/lab/TimelineDot";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { IndeterminateCheckBoxOutlined } from "@mui/icons-material";

const NewsStory = ({ newsStory, theme, metaData }) => {
  //const mainStory = stories[0];
  const [open, setOpen] = React.useState(false);
  const isDesktop = theme.breakpoints.up("xs");
  return (
    <>
      <Grid item container>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={
              open
                ? {
                    //borderBottomWidth: 0,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }
                : {}
            }
          >
            <Grid item container>
              <Grid item xs={12} md={2.5}>
                <CardMedia
                  component="img"
                  sx={{ height: { sm: "100%" } }}
                  image={newsStory[0].image}
                  alt="News Image"
                />
              </Grid>
              <Grid item xs={12} md={9.5}>
                <CardContent
                  sx={{ paddingX: 1.5, paddingY: 1, paddingBottom: 1 }}
                  style={{ paddingBottom: theme.spacing(1) }}
                >
                  <Grid container spacing={0} direction={"column"}>
                    <Grid
                      item
                      container
                      alignItems={"center"}
                      direction={"row"}
                      spacing={0.5}
                    >
                      <Grid item>
                        <Avatar
                          sx={{ width: 20, height: 20, marginRight: 0.5 }}
                          src={metaData.favicons[newsStory[0].source_name]}
                          alt={newsStory[0].source_name}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="text.secondary">
                          {newsStory[0].source_name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="text.secondary">
                          {"•"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="text.secondary">
                          {moment(newsStory[0].date * 1000).fromNow()}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Link
                        href={newsStory[0].url}
                        variant="h6"
                        underline="hover"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          //marginTop: "0.35em",
                        }}
                        color={"inherit"}
                        target="_blank"
                        rel="noopener"
                      >
                        {newsStory[0].title}
                      </Link>
                      <Divider
                        sx={{
                          width: "100%",
                          display: { xs: "none", sm: "block" },
                        }}
                      />
                    </Grid>
                    <Grid item sx={{ display: { xs: "none", sm: "block" } }}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        component="div"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          //marginTop: "0.35em",
                        }}
                      >
                        {newsStory[0].description}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      {!open &&
                        newsStory.slice(1).map((newsStory, index) => (
                          <Grid item key={index}>
                            <Avatar
                              sx={{
                                width: 20,
                                height: 20,
                                marginRight: 0.25,
                              }}
                              src={metaData.favicons[newsStory.source_name]}
                              alt={newsStory.source_name}
                            />
                          </Grid>
                        ))}
                      <Grid item>
                        <IconButton
                          size="small"
                          sx={{ padding: 0 }}
                          onClick={() => setOpen(!open)}
                        >
                          {newsStory.length > 1 ? (
                            open ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )
                          ) : null}
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={open} timeout="auto">
            <Paper
              variant="outlined"
              sx={{
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderTopWidth: 0,
              }}
            >
              <List disablePadding>
                {newsStory.slice(1).map((article, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      dense={true}
                      key={`button-${index}`}
                      disablePadding
                    >
                      <ListItemButton
                        href={article.url}
                        target="_blank"
                        rel="noopener"
                        dense={true}
                        disableGutters
                        sx={{
                          paddingX: 1.5,
                        }}
                      >
                        <Avatar
                          sx={{ width: 20, height: 20, marginRight: 1 }}
                          src={metaData.favicons[article.source_name]}
                          alt={article.source}
                        />
                        <ListItemText>{article.title}</ListItemText>
                        <ListItemText
                          //sx={{ marginLeft: 1 }}
                          variant="caption"
                          align="right"
                          secondary={moment(article.date * 1000).fromNow()}
                          secondaryTypographyProps={{
                            variant: "caption",
                            align: "right",
                            color: "text.secondary",
                            sx: { display: { xs: "none", sm: "block" } },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                    {index < newsStory.length - 2 && (
                      <Divider component="li" key={`divider-${index}`} />
                    )}
                  </React.Fragment>
                ))}
              </List>
              {/* <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {newsStory.slice(1).map((article) => (
                  <>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot variant="outlined" sx={{ borderWidth: 0 }}>
                          <Avatar
                            sx={{ width: 20, height: 20 }}
                            src={article.favicon}
                            alt={article.source}
                          />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Link
                          href={article.url}
                          target="_blank"
                          rel="noopener"
                          underline="hover"
                        >
                          <Typography variant="body2" color="text.secondary">
                            {article.title}
                          </Typography>
                        </Link>
                        <Typography
                          variant="caption"
                          align="right"
                          color="text.secondary"
                          sx={{
                            display: { xs: "none", sm: "block" },
                          }}
                        >
                          {moment(article.date * 1000).fromNow()}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </>
                ))}
              </Timeline> */}
            </Paper>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
};

export default NewsStory;
