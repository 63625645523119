import { API_ENDPOINT } from "../constants";

const sendRequest = async (
  url,
  payload = null,
  params = null,
  type = "GET",
  setLoading = null
) => {
  //const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    //Authorization: `Bearer ${token}`,
  };
  const options = {
    method: type,
    headers,
  };
  if (setLoading) {
    setLoading(true);
  }
  if (payload) {
    options.body = JSON.stringify(payload);
  }
  let requestUrl = API_ENDPOINT + url;
  if (params) {
    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    requestUrl += `?${queryString}`;
  }
  const response = await fetch(requestUrl, options);
  try {
    var data = await response.json();
    setLoading(false);
  } catch (e) {
    setLoading(false);
    throw new Error("Something went wrong");
  }
  if (!response.ok) {
    setLoading(false);
    if (response.status === 401) {
      if (url === "auth") {
        throw new Error(data.detail);
      } else {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    } else if (response.status === 422) {
      throw new Error(data.detail[0].loc[1] + " - " + data.detail[0].msg);
    } else {
      throw new Error(data.detail || "Something went wrong");
    }
  }
  return data;
};

export default sendRequest;
